export function lazyloadElements(elements, callback) {
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target)
          observer.unobserve(entry.target)
        }
      })
    })

    elements.forEach((element) => {
      observer.observe(element)
    })
  } else {
    let lazyloadThrottleTimeout

    const isVisible = function (elem) {
      const bounding = elem.getBoundingClientRect()
      const vHeight = (window.innerHeight || document.documentElement.clientHeight)
      return (
        (bounding.top > 0 || bounding.bottom > 0)
        && bounding.top < vHeight
        && elem.offsetParent !== null // hidden by styles
      )
    }

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout)
      }
      lazyloadThrottleTimeout = setTimeout(() => {
        elements.forEach((element) => {
          if (isVisible(element)) {
            callback(element)
          }
        })
        if (elements.length == 0) {
          document.removeEventListener('scroll', lazyload)
          window.removeEventListener('resize', lazyload)
          window.removeEventListener('orientationChange', lazyload)
        }
      }, 200)
    }

    document.addEventListener('scroll', lazyload)
    window.addEventListener('resize', lazyload)
    window.addEventListener('orientationChange', lazyload)
    lazyload()
  }
}
