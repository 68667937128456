import { ready } from '../utils/ready'
import { lazyloadElements } from '../utils/lazyload-elements'

ready(() => {
  const loadScripts = function () {
    lazyloadElements(
      document.querySelectorAll('.js-load-script'),
      (element) => {
        const url = element.getAttribute('data-script')
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = function(){
          // remote script has loaded
        };
        script.src = url;
        document.body.appendChild(script);
      },
    )
  }
  loadScripts()
})
