import { ready } from '../utils/ready'
import { lazyloadElements } from '../utils/lazyload-elements'

ready(() => {
  const loadVideos = function () {
    lazyloadElements(
      document.querySelectorAll('.js-load-embed'),
      (video) => {
        const hidden = video.querySelector('[hidden]')
        let content = hidden.innerHTML
        // get content from within html comment
        content = content.replace(/<!--([\S\s]+?)-->/g, '$1')
        // replace content
        video.innerHTML = content
        video.classList.remove('js-load-embed')
        video.classList.add('js-embed-loaded')
      },
    )
  }
  loadVideos()
})
